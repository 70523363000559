import { defineStore } from "pinia";

import { APICompanySettings } from "@/api/company-settings.js";
import { companySettingsNames } from "@/constants/companySettings.js";
import { setMomentDefaultTimezone } from "@/utils/dates";

function getCompanySettingByName(state, companySettingName) {
  return state.data.find(item => item.name == companySettingName);
}

export const useCompanySettingsStore = defineStore("companySettings", {
  state: () => {
    return {
      data: [],
    };
  },
  getters: {
    getOneCompanySettingByName: state => companySettingName => {
      return Object.values(state.data).find(item => item.name == companySettingName);
    },
    getMultipleCompanySettingsByName: state => companySettingName => {
      return Object.values(state.data).filter(item => item.name == companySettingName);
    },
    getValueToBoolean: state => companySettingName => {
      const settings = getCompanySettingByName(state, companySettingName);
      return settings && settings.value ? !!Number(settings.value) : false;
    },
    getValueToNumber: state => companySettingName => {
      const settings = getCompanySettingByName(state, companySettingName);
      return settings && settings.value ? Number(settings.value) : 0;
    },
    getValueToString: state => companySettingName => {
      const settings = getCompanySettingByName(state, companySettingName);
      return settings && settings.value ? settings.value : "";
    },
    getValueToObject: state => companySettingName => {
      const settings = getCompanySettingByName(state, companySettingName);
      return settings && settings.value ? JSON.parse(settings.value) : null;
    },
    getValueToArray: state => companySettingName => {
      return state.data.filter(item => item.name == companySettingName);
    },

    getTimezoneValue() {
      return this.getOneCompanySettingByName(companySettingsNames.SETTING_TIMEZONE)?.value;
    },
  },
  actions: {
    createNotifyBefore(payload) {
      return APICompanySettings.createNotifyBefore(payload).then(response => {
        this.data.push(response.data);
      });
    },
    updateNotifyBefore(payload) {
      return APICompanySettings.updateNotifyBefore(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    createNotifyAfter(payload) {
      return APICompanySettings.createNotifyAfter(payload).then(response => {
        this.data.push(response.data);
      });
    },
    updateNotifyAfter(payload) {
      return APICompanySettings.updateNotifyAfter(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateNotifyOwner(payload) {
      return APICompanySettings.updateNotifyOwner(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateReminderText(payload) {
      return APICompanySettings.updateReminderText(payload);
    },
    updateFormSetting(payload) {
      return APICompanySettings.updateFormSetting(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updatePublicWebsiteSetting(payload) {
      return APICompanySettings.updatePublicWebsiteSetting(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateEnabledPublicWebsiteAccessSetting(payload) {
      return APICompanySettings.updateEnabledPublicWebsiteAccessSetting(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updatePublicWebsiteSubscriptionDescription(payload) {
      return APICompanySettings.updatePublicWebsiteSubscriptionDescription(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateEnabledPublicWebsiteEmbeddedAccessSetting(payload) {
      return APICompanySettings.updateEnabledPublicWebsiteEmbeddedAccessSetting(payload).then(
        () => {
          const index = this.data.findIndex(item => item.id == payload.id);
          this.data[index] = payload;
        }
      );
    },
    updateObjectPlaceholderSetting(payload) {
      return APICompanySettings.updateObjectPlaceholderSetting(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateNotifTimeInterval(payload) {
      return APICompanySettings.updateNotifTimeInterval(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateTimezone(payload) {
      return APICompanySettings.updateTimezone(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateAccessInvoiceProviderSettings(payload) {
      return APICompanySettings.updateAccessInvoiceProviderSettings(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    updateEnabledWhatsappKeysSettings(payload) {
      return APICompanySettings.updateEnabledWhatsappKeysSettings(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload;
      });
    },
    deleteNotifyBefore(payload) {
      return APICompanySettings.deleteNotifyBefore(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    deleteNotifyAfter(payload) {
      return APICompanySettings.deleteNotifyAfter(payload).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    getSettingsForPlanner(payload) {
      return APICompanySettings.getSettingsForPlanner(payload).then(res => {
        this.data = res.data.items;

        const settingTimezone = this.getOneCompanySettingByName(
          companySettingsNames.SETTING_TIMEZONE
        );
        setMomentDefaultTimezone(settingTimezone?.value);
      });
    },
    getSettingsForPublicWebsite(payload) {
      return APICompanySettings.getSettingsForPublicWebsite(payload).then(res => {
        this.data.push(res.data);
      });
    },
    getSettingsForPublicWebsiteScheduleEmbedded(payload) {
      return APICompanySettings.getSettingsForPublicWebsiteScheduleEmbedded(payload).then(res => {
        this.data.push(res.data);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});

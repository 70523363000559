import { defineStore } from "pinia";

import { APINotes } from "@/api/notes.js";

export const useNotesStore = defineStore("notes", {
  state: () => {
    return {
      data: [],
      totalItems: 0,
      displayedItems: 0,
      page: 1,
      itemsPerPage: 10,
    };
  },
  getters: {
    get: state => id => state.data.find(item => item.id == id),
    getAllItems: state => () => {
      return state.data
        .sort((a, b) => {
          if (a.priority !== b.priority) {
            return b.priority - a.priority;
          }
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        })
        .map(item => ({ ...item }));
    },
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
  },
  actions: {
    fetchPaged(pageNr, searchInput) {
      APINotes.getPaged(pageNr, searchInput).then(res => {
        if (pageNr > 1) {
          res.data.items.forEach(note => {
            this.insertOrUpdate(note);
          });
        } else {
          this.data = res.data.items;
        }

        this.totalItems = res.data.totalItems;
        this.displayedItems = pageNr * this.itemsPerPage;
        this.page = pageNr;
      });
    },
    fetch() {
      return APINotes.getAll().then(res => {
        this.data = res.data.items;
        this.totalItems = res.data.totalItems;
      });
    },
    apiGet(id) {
      return APINotes.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    apiCreate(payload) {
      return new Promise((resolve, reject) => {
        APINotes.post(payload)
          .then(res => {
            this.data.push(res.data);

            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    apiUpdate(payload) {
      return APINotes.put(payload.id, payload).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    apiRemove(payload) {
      return APINotes.delete(payload.id).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data.splice(index, 1);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(announceId) {
      const index = this.data.findIndex(item => item.id == announceId);
      if (index != "-1") this.data.splice(index, 1);
    },
  },
});

import { defineStore } from "pinia";

import _ from "lodash-es";

import { APIAgendas } from "@/api/agendas.js";
import { weekDaysNumber, calendarView } from "@/constants/agendas.js";
import { useEventTypesStore } from "@/stores/models-eventTypes.js";

export const useAgendasStore = defineStore("agendas", {
  state: () => {
    return {
      agendaChanged: false,
      plannerAuthToken: null,
      currentAgendaPreferredCalendarView: null,

      data: [],
    };
  },
  getters: {
    get: state => id => {
      return state.data.find(agenda => agenda.id == id);
    },
    count: state => {
      return state.data.length;
    },
    noAgendas: state => {
      return state.data.length === 0;
    },
    getFirst: state => {
      return state.data[0] || null;
    },
    getAll: state => {
      return (
        state.data
          .map(item => ({ ...item }))
          // NOTE: Default order descending by createdAt
          .sort((a, b) => {
            return a.createdAt < b.createdAt ? 1 : -1;
          })
      );
    },
    getAllIDs(state) {
      return (
        state.data
          // NOTE: Default order ascending by name
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })
          .map(item => item.id)
      );
    },
    getAgendaAvailableDays: state => agendaId => {
      const theAgenda = state.data.find(agenda => agenda.id == agendaId);
      return theAgenda.schedule.filter(day => day.use);
    },
    getAllAgendasAvailableDays() {
      return this.data.map(item => {
        return item.schedule.filter(day => day.use);
      });
    },
    getAllAgendasUnavailableDays(state) {
      const theAgendas = state.data.map(item =>
        item.schedule.filter(day => !day.use).map(day => day.day)
      );

      let unavailableDaysOfTheWeekName = [];
      theAgendas.forEach((days, index) => {
        if (index == 0) unavailableDaysOfTheWeekName = days;
        else unavailableDaysOfTheWeekName = _.intersection(days, unavailableDaysOfTheWeekName);
      });

      const unavailableDaysOfTheWeekNumbers = [];
      unavailableDaysOfTheWeekName.forEach(day => {
        const weekDayNumber = weekDaysNumber.find(dayNumber => dayNumber.day === day).number;
        if (unavailableDaysOfTheWeekNumbers.indexOf(weekDayNumber) === -1)
          unavailableDaysOfTheWeekNumbers.push(weekDayNumber);
      });

      return unavailableDaysOfTheWeekNumbers;
    },

    getAgendaUnavailableDays: state => agendaId => {
      const theAgenda = state.data.find(agenda => agenda.id == agendaId);
      return theAgenda.schedule.filter(day => !day.use);
    },
    getCalendarDaysIntervals: state => agendaId => {
      if (agendaId == undefined) {
        const agendaObject = [...state.data].shift();
        if (agendaObject != undefined) agendaId = agendaObject.id;
        else return [];
      }

      const agendaAvailableDays = state.getAgendaAvailableDays(agendaId);
      if (!agendaAvailableDays) {
        return [];
      }

      const daysIntervals = [];
      agendaAvailableDays.forEach(availableDay => {
        availableDay.intervals.forEach(interval => {
          const weekDayNr = weekDaysNumber.find(item => item.day === availableDay.day);
          const processedInterval = {
            daysOfWeek: [weekDayNr.number],
            startTime: interval.from,
            endTime: interval.to,
          };
          daysIntervals.push(processedInterval);
        });
      });
      return daysIntervals;
    },
    getMultipleAgendaDaysIntervals() {
      const allAgendasAvailableDays = this.getAllAgendasAvailableDays;

      const daysIntervals = [];
      allAgendasAvailableDays.forEach(agendaAvailableDays => {
        agendaAvailableDays.forEach(availableDay => {
          availableDay.intervals.forEach(interval => {
            const weekDayNr = weekDaysNumber.find(item => item.day === availableDay.day);
            const processedInterval = {
              daysOfWeek: [weekDayNr.number],
              startTime: interval.from,
              endTime: interval.to,
            };
            daysIntervals.push(processedInterval);
          });
        });
      });
      return daysIntervals;
    },
    getAgendaUnavailableDaysArrayOfNbs: state => agendaId => {
      if (agendaId == undefined) {
        const agendaObject = [...state.data].shift();
        if (agendaObject != undefined) agendaId = agendaObject.id;
        else return [];
      }

      const agendaUnavailableDays = state.getAgendaUnavailableDays(agendaId);
      if (!agendaUnavailableDays) {
        return [];
      }

      const arrayOfNbs = [];
      agendaUnavailableDays.forEach(unavailableDay => {
        const weekDayNr = weekDaysNumber.find(item => item.day === unavailableDay.day);
        arrayOfNbs.push(weekDayNr.number);
      });
      return arrayOfNbs;
    },
    getPlannerAuthToken(state) {
      return state.plannerAuthToken;
    },

    getAgendaCalendarView: state => agendaId => {
      const agenda = state.data.find(agenda => agenda.id == agendaId);
      if (agenda.calendarView == calendarView.LIST) {
        return "listView";
      } else if (agenda.calendarView == calendarView.MONTH) {
        return "dayGridMonth";
      } else if (agenda.calendarView == calendarView.WEEK) {
        return "timeGridWeek";
      } else if (agenda.calendarView == calendarView.DAY) {
        return "timeGridDay";
      } else {
        return "";
      }
    },
  },
  actions: {
    apiUpdate(payload) {
      return APIAgendas.put(payload.id, payload).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    setPlannerStatus(payload) {
      const index = this.data.findIndex(item => item.id == payload.agendaId);
      this.data[index].planner.isActive = payload.value;
    },
    setPlannerPassword(payload) {
      const index = this.data.findIndex(item => item.id == payload.agendaId);
      this.data[index].planner.password = payload.value;
    },
    setScheduleSettings(payload) {
      const index = this.data.findIndex(item => item.id == payload.agendaId);
      this.data[index].schedule.splice(payload.index, 1, JSON.parse(JSON.stringify(payload.data)));
    },
    getPlanner(payload) {
      return APIAgendas.getPlanner(payload).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data;
      });
    },
    getPlannerAuth(payload) {
      return APIAgendas.getPlannerAuth(payload).then(res => {
        const index = this.data.findIndex(item => item.id == res.data.id);
        this.data[index] = res.data.agenda;
        this.plannerAuthToken = res.data.accessToken;
        this.data.push(res.data.agenda);

        const eventTypesStore = useEventTypesStore();
        eventTypesStore.data = res.data.eventTypes;
      });
    },
    updatePlannerAuthToken(payload) {
      this.plannerAuthToken = payload;
    },
    changeAgenda() {
      this.agendaChanged = !this.agendaChanged;
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    create(payload) {
      return new Promise((resolve, reject) => {
        APIAgendas.post(payload)
          .then(res => {
            if (res.data.agenda) {
              this.data.push(res.data.agenda);
            }
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    remove(id) {
      return APIAgendas.delete(id).then(() => {
        const index = this.data.findIndex(item => item.id == id);
        if (index != "-1") {
          this.data.splice(index, 1);
        }
      });
    },
  },
});

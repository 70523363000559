import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Notes {
  getAll(pagination = true, order = "desc") {
    const params = {};
    if (!pagination) {
      params["pagination"] = false;
    }
    params["order"] = order;
    return axios.get(Links.NOTES_LINK() + "?" + qs.stringify(params));
  }
  getPaged(page = 1, searchInput = null, itemsPerPage = 10, order = "desc") {
    const params = {};
    if (searchInput) {
      params["searchInput"] = searchInput;
    }
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    return axios.get(Links.NOTES_LINK() + "?" + qs.stringify(params));
  }
  get(id) {
    return axios.get(Links.NOTE_LINK(id));
  }
  post(item) {
    return axios.post(Links.NOTES_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.NOTE_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.NOTE_LINK(id));
  }
}

export const APINotes = new Notes();

import { defineStore } from "pinia";

import { APIObjects } from "@/api/objects.js";
import { useSubscriptionsStore } from "@/stores/models-subscriptions.js";

export const useEventObjectsStore = defineStore("eventObjects", {
  state: () => {
    return {
      activeSearchTerm: "",
      totalItems: 0,
      displayedItems: 0,
      itemsPerPage: 10,
      isSearchInputFocused: false,
      sortingOrder: "desc",
      data: [],
    };
  },
  getters: {
    get: state => id => state.data.find(object => object.id == id),
    getAll: state => order => {
      return state.data
        .sort((a, b) => {
          let comparison = a.updatedAt - b.updatedAt;
          if (comparison === 0) {
            comparison = a.name.localeCompare(b.name);
          }
          return order === "desc" ? -comparison : comparison;
        })
        .map(item => ({ ...item }));
    },

    getActiveSearchTerm: state => {
      return state.activeSearchTerm;
    },
    isPagerNeeded: state => {
      return state.totalItems > state.displayedItems;
    },
  },
  actions: {
    apiFetchPaged(payload) {
      let page = 1;
      if (Object.hasOwn(payload, "page")) {
        page = payload.page;
      }
      let searchInput = null;
      if (Object.hasOwn(payload, "searchInput")) {
        searchInput = payload.searchInput;
      }
      return APIObjects.getPaged(this.itemsPerPage, searchInput, page, this.sortingOrder)
        .then(response => {
          if (page > 1) {
            this.data = this.data.concat(response.data.items);
          } else {
            this.data = response.data.items;
          }

          this.totalItems = response.data.totalItems;
          this.displayedItems = page * this.itemsPerPage;
        })
        .catch(err => {
          throw new Error("Get EventObject Error", err);
        });
    },
    fetch(payload) {
      let page = 1;
      if (Object.prototype.hasOwnProperty.call(payload, "page")) {
        page = payload.page;
      }
      return APIObjects.getPaged(this.itemsPerPage, null, page, this.sortingOrder).then(res => {
        // NOTE: When we fetch for new page, we keep old entries and concat with the new array of entries
        if (page > 1) {
          this.data = this.data.concat(res.data.items);
        } else {
          this.data = res.data.items;
        }

        this.totalItems = res.data.totalItems;
        this.displayedItems = page * this.itemsPerPage;
      });
    },
    apiGet(id) {
      return APIObjects.get(id).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    create(payload) {
      return APIObjects.post(payload.object).then(res => {
        this.data.push(res.data);
      });
    },
    update(payload) {
      return APIObjects.put(payload.id, payload.object).then(() => {
        const index = this.data.findIndex(item => item.id == payload.id);
        this.data[index] = payload.object;
      });
    },
    remove(id) {
      if (this.isPagerNeeded) {
        return this.apiRemoveFromList(id);
      } else {
        return this.apiRemove(id);
      }
    },
    apiRemove(id) {
      return APIObjects.delete(id).then(() => {
        const index = this.data.findIndex(item => item.id == id);
        this.data.splice(index, 1);
      });
    },
    apiRemoveFromList(id) {
      const currentPage = Math.ceil(this.displayedItems / this.itemsPerPage);
      return APIObjects.deleteFromList(
        id,
        currentPage,
        this.itemsPerPage,
        this.activeSearchTerm,
        this.sortingOrder
      ).then(res => {
        const index = this.data.findIndex(item => item.id == id);
        this.data.splice(index, 1);

        this.totalItems = res.data.totalItems;
        if (res.data.eventObject) {
          this.data.push(res.data.eventObject);
        }
      });
    },
    accessPersonalSpace(payload) {
      return APIObjects.accessPersonalSpace(payload).then(res => {
        const subscriptionsStore = useSubscriptionsStore();
        subscriptionsStore.data = res.data.subscriptions;
        subscriptionsStore.accessLinkCode = res.data.accessLinkCode;
      });
    },
    apiGetForFilters(searchInput) {
      return APIObjects.getForFilters(searchInput).then(res => {
        this.data = res.data;
      });
    },

    performSearch(activeSearchTerm) {
      if (this.activeSearchTerm !== activeSearchTerm) {
        this.setActiveSearchTerm(activeSearchTerm);
      }
    },
    resetSearch() {
      this.setActiveSearchTerm("");
    },

    setActiveSearchTerm(activeSearchTerm) {
      this.activeSearchTerm = activeSearchTerm;
    },
    insertOrUpdate(object) {
      if (!object) return;

      const index = this.data.findIndex(item => item.id == object.id);
      if (index != "-1") {
        // Update
        this.data[index] = object;
      } else {
        // Insert
        this.data.push(object);
      }
    },
    delete(objectId) {
      const index = this.data.findIndex(item => item.id == objectId);
      if (index != "-1") this.data.splice(index, 1);
    },
    updateEventObjectContactHistory(id, contactHistory) {
      const index = this.data.findIndex(item => item.id == id);
      if (this.data[index].contactHistories) {
        this.data[index].contactHistories.unshift({
          content: contactHistory.content,
          updatedAt: contactHistory.updatedAt,
        });
      } else {
        this.data[index]["contactHistories"] = [
          {
            content: contactHistory.content,
            updatedAt: contactHistory.updatedAt,
          },
        ];
      }
    },
  },
});

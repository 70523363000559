import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class CompanySettings {
  createNotifyBefore(item) {
    return axios.post(Links.COMPANY_SETTINGS_NOTIFY_BEFORE(), item);
  }
  updateNotifyBefore(item) {
    return axios.put(Links.COMPANY_SETTING_NOTIFY_BEFORE(item.id, item.name), item);
  }
  createNotifyAfter(item) {
    return axios.post(Links.COMPANY_SETTINGS_NOTIFY_AFTER(), item);
  }
  updateNotifyAfter(item) {
    return axios.put(Links.COMPANY_SETTING_NOTIFY_AFTER(item.id, item.name), item);
  }
  updateNotifyOwner(item) {
    return axios.put(Links.COMPANY_SETTING_NOTIFY_OWNER(item.id, item.name), item);
  }
  updateReminderText(item) {
    return axios.put(Links.COMPANY_SETTING_REMINDER_TEXT(item.id, item.name), item);
  }
  updateFormSetting(item) {
    return axios.put(Links.COMPANY_SETTING_FORM(item.id, item.name), item);
  }
  updatePublicWebsiteSetting(item) {
    return axios.put(Links.COMPANY_SETTING_PUBLIC_WEBSITE(item.id, item.name), item);
  }
  updateEnabledPublicWebsiteAccessSetting(item) {
    return axios.put(Links.COMPANY_SETTING_ENABLED_PUBLIC_WEBSITE_ACCESS(item.id, item.name), item);
  }
  updatePublicWebsiteSubscriptionDescription(item) {
    return axios.put(
      Links.COMPANY_SETTING_WEBSITE_SUBSCRIPTION_DESCRIPTION(item.id, item.name),
      item
    );
  }
  updateEnabledPublicWebsiteEmbeddedAccessSetting(item) {
    return axios.put(
      Links.COMPANY_SETTING_ENABLED_PUBLIC_WEBSITE_EMBEDDED_ACCESS(item.id, item.name),
      item
    );
  }
  updateObjectPlaceholderSetting(item) {
    return axios.put(Links.COMPANY_SETTING_OBJECT_PLACEHOLDER(item.id, item.name), item);
  }
  updateNotifTimeInterval(item) {
    return axios.put(Links.COMPANY_SETTING_NOTIFICATION_TIME_INTERVAL(item.id, item.name), item);
  }
  updateTimezone(item) {
    return axios.put(Links.COMPANY_SETTING_TIMEZONE(item.id, item.name), item);
  }
  updateAccessInvoiceProviderSettings(item) {
    return axios.put(
      Links.COMPANY_SETTING_ACCESS_INVOICE_PROVIDER_SETTINGS(item.id, item.name),
      item
    );
  }
  updateEnabledWhatsappKeysSettings(item) {
    return axios.put(
      Links.COMPANY_SETTING_ENABLED_WHATSAPP_KEYS_SETTINGS(item.id, item.name),
      item
    );
  }
  deleteNotifyBefore(item) {
    return axios.delete(Links.COMPANY_SETTING_NOTIFY_BEFORE(item.id, item.name), item);
  }
  deleteNotifyAfter(item) {
    return axios.delete(Links.COMPANY_SETTING_NOTIFY_AFTER(item.id, item.name), item);
  }
  getSettingsForPlanner(item) {
    const params = {};
    params["authToken"] = item.authToken;
    return axios.get(Links.COMPANY_SETTING_PLANNER() + "?" + qs.stringify(params));
  }
  getSettingsForPublicWebsite(companyId) {
    return axios.get(Links.COMPANY_SETTING_GET_PUBLIC_WEBSITE_DATA(companyId));
  }
  getSettingsForPublicWebsiteScheduleEmbedded(companyId) {
    return axios.get(Links.COMPANY_SETTING_GET_PUBLIC_WEBSITE_SCHEDULE_EMBEDDED(companyId));
  }
}

export const APICompanySettings = new CompanySettings();

import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Companies {
  get() {
    return axios.get(Links.COMPANY_LINK());
  }
  put(item) {
    return axios.put(Links.COMPANY_LINK(), item);
  }
  get_subscription_limit() {
    return axios.get(Links.COMPANY_SUBSCRIPTION_LIMIT());
  }
  request_subscription_change(subscriptionPlanId, isCanceled = false) {
    const params = {};
    if (subscriptionPlanId) {
      params["subscriptionPlanId"] = subscriptionPlanId;
    }
    if (isCanceled) {
      params["cancel"] = isCanceled;
    }
    return axios.get(Links.COMPANY_REQUEST_SUBSCRIPTION_CHANGE() + "?" + qs.stringify(params));
  }

  clearFutureSubscription() {
    return axios.post(Links.COMPANY_CLEAR_FUTURE_SUBSCRIPTION);
  }
  cancelSubscription() {
    return axios.post(Links.COMPANY_CANCEL_SUBSCRIPTION);
  }
  downgradeSubscription(data) {
    return axios.post(Links.COMPANY_DOWNGRADE_SUBSCRIPTION, data);
  }
  upgradeSubscription(data) {
    return axios.post(Links.COMPANY_UPGRADE_SUBSCRIPTION, data);
  }
  changeSubscriptionOnTrial(data) {
    return axios.post(Links.COMPANY_CHANGE_SUBSCRIPTION_ON_TRIAL, data);
  }

  selectSubscriptionPlanInSignupWizard(payload) {
    return axios.post(Links.COMPANY_SELECT_SUBSCRIPTION_PLAN_IN_SIGNUP_WIZARD(), payload);
  }
  checkSubdomain(subdomain) {
    return axios.get(Links.COMPANY_CHECK_SUBDOMAIN(subdomain));
  }
  signUpWizzardCheckSubdomain(subdomain) {
    return axios.get(Links.SIGNUP_WIZARD_CHECK_SUBDOMAIN(subdomain));
  }
  updateSubdomain(subdomain) {
    return axios.put(Links.COMPANY_UPDATE_SUBDOMAIN(), { subdomain });
  }
  updateWhatsappKeys(whatsappKeys) {
    return axios.put(Links.COMPANY_UPDATE_WHATSAPP_KEYS(), whatsappKeys);
  }
  updateWhatsappStatus(whatsappStatus) {
    return axios.put(Links.COMPANY_UPDATE_WHATSAPP_STATUS(), whatsappStatus);
  }
  validateWhatsappKeys(validationPhone) {
    return axios.post(Links.COMPANY_VALIDATE_WHATSAPP_KEYS(), validationPhone);
  }
  validateCodeWhatsappKeys(validationCode) {
    return axios.post(Links.COMPANY_VALIDATE_CODE_WHATSAPP_KEYS(), validationCode);
  }

  completeSignUpWizard(object) {
    return axios.post(Links.COMPANY_COMPLETE_SIGNUP_WIZARD, object);
  }
  completeSignUpWizardWithExternalAuth(object) {
    return axios.post(Links.COMPANY_COMPLETE_SIGNUP_WIZARD_EXTERNAL_AUTH, object);
  }
  completeInitialTour() {
    return axios.post(Links.COMPANY_COMPLETE_INITIAL_TOUR);
  }
  saveInvoiceProviderKeys(data) {
    return axios.post(Links.COMPANY_SAVE_INVOICE_PROVIDER_KEYS(), data);
  }
}

export const APICompanies = new Companies();
